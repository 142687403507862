document.addEventListener('DOMContentLoaded', () => {
    menuScript();

})

function menuScript() {

    const menu = document.querySelector('header nav');
    const toggleButton = document.querySelector('button.layout__burger.toggle-nav');

    const menuLis = document.querySelectorAll('nav > ul > li')
    const menuLinks = document.querySelectorAll('nav > ul > li > a')
    const subMenus = menu.querySelectorAll('nav > ul > li > a + ul')
    const subMenusSub = menu.querySelectorAll('nav > ul > li > a + ul a + ul')
    const subMenuLis = menu.querySelectorAll('a + ul > li')
    const subMenuLiLinks = menu.querySelectorAll('a + ul > li > a')

    // Helper function
    function addListenerToElement(element, eventType, listener) {
        element.addEventListener(eventType, listener)
    }

    // Remove active class from menu items, add it on clicked element and toggle show in sub-menus
    function toggleActiveItem(event) {
        let clicked = event.target.parentElement.classList.contains('main-nav__menu-item--active');
        let parentItem = event.target.parentElement;
        let siblingItem = event.target.nextElementSibling;


        for (const value of menuLis) {
            value.classList.remove('main-nav__menu-item--active')
        }
        for (const value of subMenus) {
            value.classList.remove('open')
        }

        if (clicked == false) {
            parentItem.classList.add('main-nav__menu-item--active')

            if (siblingItem) {
                siblingItem.classList.add('open')
                dropDownAnimation(event.target)
            }
        }
    }
    function toggleActiveItemSub(event) {
        let clicked = event.target.parentElement.classList.contains('main-nav__menu-item--active');

        let parentItem = event.target.parentElement;
        let siblingItem = event.target.nextElementSibling;

        for (const value of subMenuLis) {
            value.classList.remove('main-nav__menu-item--active')
        }
        for (const value of subMenusSub) {
            value.classList.remove('open')
        }

        if (clicked == false) {
            parentItem.classList.add('main-nav__menu-item--active')

            siblingItem.classList.add('open')
            dropDownAnimation(event.target)
        }

    }

    //Toggle animated menu opening in mobile view, dynamically based on menu items quantity
    function toggleNav(event) {
        let e = event.target;
        const siblingItem = e.parentElement.nextElementSibling.firstElementChild.firstElementChild;
        e.classList.toggle('js-menu__icon--open')
        //e.parentElement.previousElementSibling.firstElementChild.classList.toggle('js--hide')
        e.parentElement.nextElementSibling.classList.toggle('js--display');
        siblingItem.classList.toggle('open')
        dropDownAnimation(siblingItem)
        document.querySelectorAll('.main-nav__sub-menu-item-link').forEach(menuItem => {
            menuItem.parentElement.classList.remove('menu-item--active-trail');
        });

    }

    // Animation for dropdowns
    function dropDownAnimation(clicked) {
        let clickedItem = clicked
        let clickedItemSibling = clickedItem.nextElementSibling

        if (clickedItemSibling !== null) {
            let menuItems = clickedItemSibling.children
            let menuItemHeight = menuItems[0].offsetHeight
            let menuItemLength = menuItems.length
            let menuHeight = menuItemHeight * menuItemLength

            const animationParameters = {
                duration: 350,
                easing: 'ease-in-out'
            }
            if (clickedItemSibling.classList.contains('open')) {
                clickedItemSibling.animate(
                    [{ height: '0' }, { height: menuHeight + 'px' }],
                    animationParameters
                )
            } else {
                clickedItemSibling.animate(
                    [{ height: menuHeight + 'px' }, { height: '0' }],
                    animationParameters
                )
            }
        }
    }

    // Close the open submenu  clicking everywhere
    function closeDropdown(event) {

        var specifiedElement = document.querySelector('nav.header-content__main-nav.main-nav');

        document.addEventListener('click', function (event) {
            let isClickInside = specifiedElement.contains(event.target);

            if (isClickInside) {

            }
            else {
                for (const value of menuLis) {
                    value.classList.remove('main-nav__menu-item--active')
                }
                for (const value of subMenus) {
                    value.classList.remove('open')
                }
            }
        });
    }


    for (const value of menuLinks) {
        addListenerToElement(value, 'click', toggleActiveItem)
    }

    for (const value of subMenuLiLinks) {
        addListenerToElement(value, 'click', toggleActiveItemSub)
    }

    addListenerToElement(toggleButton, 'click', toggleNav)
    addListenerToElement(window, 'mouseover', closeDropdown)
}

